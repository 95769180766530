import React, { useEffect } from "react";
import AOS from "aos";
// import textData from '../Jsons/ContentManagementSystem.json'
import page_json from "./config/config.json";
import ContactForm from "./ContactForm";
import { Helmet } from "react-helmet";
import ContactUsButton from "./ContactUsButton";

const textDataUrl = page_json.page_jsons.ContentManagementSystem;
const textDataResponse = await fetch(textDataUrl);
const textData = await textDataResponse.json();

function ContentManagementSystem() {
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <div data-aos="fade-up" className="caseStudyPage">
      <Helmet>
        <meta
          name="description"
          content="Amplify. Unlock. Empower. The entire spectrum of Financial Advisors with our integrated and comprehensive Content Management platform"
        />
        <title>
          FInancial Services Portal | Financial Services | Engineersmind
        </title>
      </Helmet>
      <div className="container-fluid m-0 p-0 brd-crmb">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="/">Home</a>
                  </li>
                  <li className="breadcrumb-item">
                    <a href="/Industries/Financial-Services">
                      Financial Services
                    </a>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    FInancial Services Portal
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid p-0">
        <div className="inner-banner">
          <img
            src={textData.layer1.banner}
            className="d-block w-100"
            alt="banner-img"
          />
          <div className="inner-banner-text">
            <div className="container">
              <div className="row animate__animated animate__fadeInLeft">
                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                  <p>Case Study</p>
                  <h1>{textData.layer1.title}</h1>
                  <ContactUsButton />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid m-0 caseStudyContent">
        <div className="container">
          <div className="row">
            <div className="col-lg-2 col-md-12 col-sm-12 col-xs-12">
              <div className="d-flex flex-column leftSection w-max">
                <div className="logo-container">
                  <a href="/">
                  <img src={textData.leftSectionContent.logo} alt="EM_Logo" />
                  </a>
                </div>
                <p className="mt-1">
                  {textData.leftSectionContent.text1}{" "}
                  <strong>{textData.leftSectionContent.text2}</strong>
                </p>
                <hr />
                <p>{textData.leftSectionContent.text3}</p>
                <hr className="mb-5" />
              </div>
            </div>

            <div className="col-lg-10 col-md-12 col-sm-12 col-xs-12">
              <section>
                <h4>{textData.keyPointsSection.title}</h4>
                <p className="mb-3">{textData.keyPointsSection.subtitle}</p>
                <ul>
                  {textData.keyPointsSection.points.map((point, index) => {
                    return <li key={index}>{point}</li>;
                  })}
                </ul>
              </section>

              <hr />

              <section className="overview">
                <h4 className="mb-3">{textData.overviewSection.title}</h4>
                <div className="d-flex justify-content-center">
                  <img src={textData.overviewSection.img} alt="overview-img" />
                </div>
                <p className="para">{textData.overviewSection.description}</p>
              </section>

              <hr />

              <section className="portal">
                <h4 className="mb-3 font-weight-bold">
                  {textData.investorPortalSection.title}
                </h4>
                <div className="container">
                  <div className="row">
                    <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                      <img
                        className="w-100"
                        src={textData.investorPortalSection.img}
                        alt="Investor Portal"
                      />
                    </div>
                    <div className="col-lg-8 col-md-8 col-sm-8 col-xs-8">
                      <p>{textData.investorPortalSection.content}</p>
                    </div>
                  </div>
                  <div className="mt-3">
                    <p className="mb-3">
                      <strong>
                        {textData.investorPortalSection.keyFeatureTitle}
                      </strong>
                    </p>
                    <div>
                      {textData.investorPortalSection.keyFeatures.map(
                        (feature, index) => {
                          return (
                            <div className="d-flex w-100 list-item" key={index}>
                              <img
                                src={textData.bulletPoints.img}
                                alt="bullet-point"
                              />
                              <div>
                                <p>
                                  <strong>{feature.title}</strong>
                                </p>
                                <p>{feature.description}</p>
                              </div>
                            </div>
                          );
                        }
                      )}
                    </div>
                  </div>
                </div>
              </section>

              <hr />

              <section className="portal">
                <h4 className="mb-3">{textData.advisorPortalSection.title}</h4>
                <div className="container">
                  <div className="row">
                    <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                      <img
                        className="w-100"
                        src={textData.advisorPortalSection.img}
                        alt="Investor Portal"
                      />
                    </div>
                    <div className="col-lg-8 col-md-8 col-sm-8 col-xs-8">
                      <p>{textData.advisorPortalSection.content}</p>
                    </div>
                  </div>
                  <div className="mt-3">
                    <p className="mb-3">
                      <strong>
                        {textData.advisorPortalSection.keyFeatureTitle}
                      </strong>
                    </p>
                    <div>
                      {textData.advisorPortalSection.keyFeatures.map(
                        (feature, index) => {
                          return (
                            <div className="d-flex w-100 list-item" key={index}>
                              <img
                                src={textData.bulletPoints.img}
                                alt="bullet-point"
                              />
                              <div>
                                <p>
                                  <strong>{feature.title}</strong>
                                </p>
                                <p>{feature.description}</p>
                              </div>
                            </div>
                          );
                        }
                      )}
                    </div>
                  </div>
                </div>
              </section>

              <hr />

              <section className="portal">
                <h4 className="mb-3">{textData.assetManagerPortal.title}</h4>
                <div className="container">
                  <div className="row">
                    <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                      <img
                        className="w-100"
                        src={textData.assetManagerPortal.img}
                        alt="Investor Portal"
                      />
                    </div>
                    <div className="col-lg-8 col-md-8 col-sm-8 col-xs-8">
                      <p>{textData.assetManagerPortal.content}</p>
                    </div>
                  </div>
                  <div className="mt-3">
                    <p className="mb-3">
                      <strong>
                        {textData.assetManagerPortal.keyFeatureTitle}
                      </strong>
                    </p>
                    <div>
                      {textData.assetManagerPortal.keyFeatures.map(
                        (feature, index) => {
                          return (
                            <div className="d-flex w-100 list-item" key={index}>
                              <img
                                src={textData.bulletPoints.img}
                                alt="bullet-point"
                              />
                              <div>
                                <p>
                                  <strong>{feature.title}</strong>
                                </p>
                                <p>{feature.description}</p>
                              </div>
                            </div>
                          );
                        }
                      )}
                    </div>
                  </div>
                </div>
              </section>

              <hr />

              <section className="portal">
                <h4 className="mb-3">
                  {textData.thirdPartyIntegrations.title}
                </h4>
                <div className="container">
                  <div className="row">
                    <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                      <img
                        className="w-100"
                        src={textData.thirdPartyIntegrations.img}
                        alt="Investor Portal"
                      />
                    </div>
                    <div className="col-lg-8 col-md-8 col-sm-8 col-xs-8">
                      <p>{textData.thirdPartyIntegrations.content}</p>
                    </div>
                  </div>
                  <div className="mt-3">
                    <ul>
                      {textData.thirdPartyIntegrations.points.map(
                        (feature, index) => {
                          return (
                            <li className="mb-2" key={index}>
                              {/* <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2"></div> */}
                              <div>
                                <p>
                                  <strong>{feature.title}</strong>:{" "}
                                  {feature.description}
                                </p>
                              </div>
                            </li>
                          );
                        }
                      )}
                    </ul>
                    <p>{textData.thirdPartyIntegrations.bottomText}</p>
                  </div>
                </div>
              </section>

              <hr />

              <section>
                <h4 className="mb-5">{textData.technicalCapabilities.title}</h4>
                <img
                  className="w-100 mb-5"
                  src={textData.technicalCapabilities.img}
                  alt="technical-capabilities-img"
                />
                {textData.technicalCapabilities.content.map((point, index) => {
                  return (
                    <p
                      key={index}
                      className="mb-3"
                      dangerouslySetInnerHTML={{ __html: point }}
                    />
                  );
                })}
              </section>

              <hr />

              <section>
                <h4 className="mb-3">
                  {textData.digitalExperiencePlatform.title}
                </h4>
                {textData.digitalExperiencePlatform.content.map(
                  (point, index) => {
                    return (
                      <p
                        key={index}
                        className="mb-3"
                        dangerouslySetInnerHTML={{ __html: point }}
                      />
                    );
                  }
                )}
              </section>

              <hr />

              <section>
                <h4 className="mb-3">{textData.impactSection.title}</h4>
                {textData.impactSection.content.map((point, index) => {
                  return (
                    <p
                      key={index}
                      className="mb-3"
                      dangerouslySetInnerHTML={{ __html: point }}
                    />
                  );
                })}
              </section>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid trust">
        <div className="container trusted-img">
          <div className="row">
            <h1>{textData.trustedBy.heading}</h1>
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <img
                src={textData.trustedBy.img}
                alt="logos"
                className="img-fluid"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid alliance">
        <div className="container">
          <div className="row">
            <div className="col-lg-7 col-md-6 col-sm-12 col-xs-12">
              <div className="heading">
                <h1>
                  <span>{textData.alliances.heading.text1}</span>
                  {textData.alliances.heading.text2}
                  <span>{textData.alliances.heading.text3}</span>
                </h1>
              </div>
              <div className="more-2">
                <a href={textData.alliances.button.url}>
                  {textData.alliances.button.text}
                  <i className="fa-solid fa-arrow-right"></i>
                </a>
              </div>
            </div>
            <div className="col-lg-5 col-md-6 col-sm-12 col-xs-12">
              <div className="gif">
                <img
                  src={textData.alliances.button.img}
                  alt="gif"
                  className="img-fluid"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <div className="container-fluid casestudy">
        <div className="container">
          <h1>
            <span>{textData.layer1.title}</span>
          </h1>
          <p>{textData.layer1.subtitle}</p>&nbsp;
          <div className="row">
            <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
              <div className="casestudy1">
                <img
                  src={textData.layer1.sections.section1.image}
                  alt="gif"
                  className="img-fluid"
                />
                <h4>{textData.layer1.sections.section1.title}</h4>
                <p>{textData.layer1.sections.section1.description}</p>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
              <div className="casestudy1">
                <img
                  src={textData.layer1.sections.section2.image}
                  alt="gif"
                  className="img-fluid"
                />
                <h4>{textData.layer1.sections.section2.title}</h4>
                <p>{textData.layer1.sections.section2.description}</p>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
              <div className="casestudy1">
                <img
                  src={textData.layer1.sections.section3.image}
                  alt="gif"
                  className="img-fluid"
                />
                <h4>{textData.layer1.sections.section3.title}</h4>
                <p>{textData.layer1.sections.section3.description}</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid cs-solution-2">
        <div className="container">
          <h1>
            <span>{textData.layer2.title.text1}</span>
            {textData.layer2.title.text2}
          </h1>
          <p>
            <i>{textData.layer2.subtitle}</i>
          </p>
          <br />
          <br />
          <h5>
            <span>{textData.layer3.title}</span>
          </h5>
          <p>{textData.layer3.subtitle}</p>&nbsp;
          <h5>
            <span>{textData.layer4.title}</span>
          </h5>
          <div className="ul-list-1">
            <div className="row">
              <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                <p>{textData.layer4.solutions.solution1.serial_no}</p>
                <ul>
                  <li> {textData.layer4.solutions.solution1.points.point1}</li>
                  <li> {textData.layer4.solutions.solution1.points.point2}</li>
                  <li> {textData.layer4.solutions.solution1.points.point3}</li>
                  <li> {textData.layer4.solutions.solution1.points.point4}</li>
                  <li> {textData.layer4.solutions.solution1.points.point5}</li>
                </ul>
              </div>
              <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                <p>{textData.layer4.solutions.solution2.serial_no}</p>
                <ul>
                  <li> {textData.layer4.solutions.solution2.points.point1}</li>
                  <li> {textData.layer4.solutions.solution2.points.point2}</li>
                  <li> {textData.layer4.solutions.solution2.points.point3}</li>
                  <li> {textData.layer4.solutions.solution2.points.point4}</li>
                </ul>
              </div>
              <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                <p>{textData.layer4.solutions.solution3.serial_no}</p>
                <ul>
                  <li> {textData.layer4.solutions.solution3.points.point1}</li>
                  <li> {textData.layer4.solutions.solution3.points.point2}</li>
                  <li> {textData.layer4.solutions.solution3.points.point3}</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid Features">
        <div className="container">
          <h1>{textData.layer5.title1}</h1>
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <img src={textData.layer5.img1} alt="gif" className="img-fluid" />
              <img src={textData.layer5.img2} alt="gif" className="img-fluid" />
            </div>
          </div>
          <br />
          <br />
          <h1>{textData.layer5.title2}</h1>
          <p>
            <i>
              {textData.layer5.subtitle2.text1}
              <b>{textData.layer5.subtitle2.text2}</b>
              {textData.layer5.subtitle2.text3}
              <b>{textData.layer5.subtitle2.text4}</b>
              {textData.layer5.subtitle2.text5}
            </i>
          </p>
          <br />
        </div>
      </div>

      <div className="container-fluid status">
        <div className="container">
          <h1>
            <span>{textData.layer6.title.text1}</span>
            {textData.layer6.title.text2}
          </h1>
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <h5>
                <span>{textData.layer6.module.module1.title}</span>
              </h5>
              <br />
              <img
                src={textData.layer6.module.module1.img}
                alt="gif"
                className="img-fluid"
              />
              <ul className="disc">
                <li>{textData.layer6.module.module1.points.point1}</li>
                <li>{textData.layer6.module.module1.points.point2}</li>
              </ul>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <h5>
                <span>{textData.layer6.module.module2.title}</span>
              </h5>
              &nbsp;
              <ul className="disc">
                <li>{textData.layer6.module.module2.points.point1}</li>
                <li>{textData.layer6.module.module2.points.point2}</li>
                <li>{textData.layer6.module.module2.points.point3}</li>
                <li>{textData.layer6.module.module2.points.point4}</li>
                <li>{textData.layer6.module.module2.points.point5}</li>
                <li>{textData.layer6.module.module2.points.point6}</li>
              </ul>
            </div>
          </div>
        </div>
      </div> */}

      <div className="container-fluid ContactForm" id="ContactForm">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <ContactForm />
            </div>
          </div>
        </div>
      </div>
      <div className="TA-bg">
        <div className="container">
          <div className="row">
            <h1>{textData.layer7.heading}</h1>
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <img
                src={textData.layer7.img}
                alt="logos"
                className="img-fluid"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ContentManagementSystem;
