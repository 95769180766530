import React from "react";

const ContactUsButton = ({ className, linkOnly = false }) => {
  return linkOnly ? (
    <a href="#ContactForm">
      Contact Us <i className="fa-solid fa-arrow-right"></i>
    </a>
  ) : (
    <div className={`lead-form-container ${className}`}>
      <a className="contact-form-link" href="#ContactForm">
        Contact Us <i className="fa-solid fa-arrow-right"></i>
      </a>
    </div>
  );
};

export default ContactUsButton;
