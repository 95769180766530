
import AOS from 'aos';
import { useEffect } from 'react';
import page_json from './config/config.json';
import ContactForm from './ContactForm';
import { Helmet } from 'react-helmet';
import ContactUsButton from './ContactUsButton';

const textDataUrl = page_json.page_jsons.Resources;
const textDataResponse = await fetch(textDataUrl);
const textData = await textDataResponse.json();

function Resources() {
	useEffect(() => {
		AOS.init(); // Initialize AOS
	}, []);

	return (
		<div data-aos="fade-up">
			<Helmet>
                <meta name="description" content="Start your Digital transformation journey today" />
                <title>Resources | Engineersmind</title>
            </Helmet>
			<div className="container-fluid m-0 p-0 brd-crmb" id="crumb-2">
				<div className="container">
					<div className="row">
						<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
							<nav aria-label="breadcrumb">
								<ol className="breadcrumb">
									<li className="breadcrumb-item"><a href="/">Home </a></li>
									<li className="breadcrumb-item active" aria-current="page">Resources</li>
								</ol>
							</nav>
						</div>
					</div>
				</div>
			</div>

			<div className="container-fluid SS">
				<div className="container">
					<div className='d-flex flex-column align-items-baseline'>
						<h1 data-aos="fade-down" data-aos-duration="1000"><span>{textData.layer1.heading.text1}</span>{textData.layer1.heading.text2}</h1>
						<ContactUsButton className="light mb-3" />
					</div>
					<h3><span>{textData.layer1.details.data1.heading}</span></h3>
					<div className="row">
						<div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
							<div className="success1">
								<img src={textData.layer1.details.data1.body1.img} alt="gif" className="img-fluid" />
								<p><span>{textData.layer1.details.data1.body1.serial_no}</span>{textData.layer1.details.data1.body1.story}</p>
							</div>
							<a href={textData.layer1.details.data1.body1.link}><i className="fa-solid fa-arrow-right"></i></a>
						</div>
						<div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
							<div className="success1">
								<img src={textData.layer1.details.data1.body2.img} alt="gif" className="img-fluid" />
								<p><span>{textData.layer1.details.data1.body2.serial_no}</span>{textData.layer1.details.data1.body2.story}</p>
							</div>
							<a href={textData.layer1.details.data1.body2.link}><i className="fa-solid fa-arrow-right"></i></a>
						</div>
						<div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
							<div className="success1">
								<img src={textData.layer1.details.data1.body3.img} alt="gif" className="img-fluid" />
								<p><span>{textData.layer1.details.data1.body3.serial_no}</span>{textData.layer1.details.data1.body3.story}</p>
							</div>
							<a href={textData.layer1.details.data1.body3.link}><i className="fa-solid fa-arrow-right"></i></a>
						</div>
					</div>
					<br></br>
					<h3><span>{textData.layer1.details.data2.heading}</span></h3>
					<div className="row">
						<div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
							<div className="success1">
								<img src={textData.layer1.details.data2.body1.img} alt="gif" className="img-fluid" />
								<p><span>{textData.layer1.details.data2.body1.serial_no}</span>{textData.layer1.details.data2.body1.story}</p>
							</div>
							<a href={textData.layer1.details.data2.body1.link}><i className="fa-solid fa-arrow-right"></i></a>
						</div>
						<div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
							<div className="success1">
								<img src={textData.layer1.details.data2.body2.img} alt="gif" className="img-fluid" />
								<p><span>{textData.layer1.details.data2.body2.serial_no}</span>{textData.layer1.details.data2.body2.story}</p>
							</div>
							<a href={textData.layer1.details.data2.body2.link}><i className="fa-solid fa-arrow-right"></i></a>
						</div>
					</div>
					<br></br>
					<h3><span>{textData.layer1.details.data3.heading}</span></h3>
					<div className="row">
						<div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
							<div className="success1">
								<img src={textData.layer1.details.data3.body1.img} alt="gif" className="img-fluid" />
								<p><span>{textData.layer1.details.data3.body1.serial_no}</span>{textData.layer1.details.data3.body1.story}</p>
							</div>
							<a href={textData.layer1.details.data3.body1.link}><i className="fa-solid fa-arrow-right"></i></a>
						</div>
					</div>
				</div>
			</div >

			<div className="container-fluid alliance">
				<div className="container">
					<div className="row">
						<div className="col-lg-7 col-md-6 col-sm-7 col-xs-7">
							<div className="heading">
								<h1><span>{textData.layer2.heading.text1}</span> {textData.layer2.heading.text2} <span>{textData.layer2.heading.text3}</span></h1>
							</div>
							<div className="more-2">
								<a href={textData.layer2.button.url}>{textData.layer2.button.text} <i className="fa-solid fa-arrow-right"></i></a>
							</div>
						</div>
						<div className="col-lg-5 col-md-6 col-sm-5 col-xs-5">
							<div className="gif">
								<img src={textData.layer2.img} alt="gif" className="img-fluid" />
							</div>
						</div>
					</div>
				</div>
			</div>
            <div className="container-fluid ContactForm" id='ContactForm'>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <ContactForm />
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid tabg alliance">
				<div className="TA-bg">
						<div className="container">
							<div className="row">
								<h1>Technology Alliances</h1>
								<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
									<img src="https://engineersmind-websitecontent.s3.us-east-1.amazonaws.com/Logos.webp" alt="logos" className="img-fluid" />
								</div>
							</div>
						</div>
					</div>
			</div>

		</div >
	)

}
export default Resources;