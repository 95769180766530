
import React, { useEffect } from 'react';
import AOS from 'aos';
import page_json from './config/config.json';
import ContactForm from './ContactForm';
import { Helmet } from 'react-helmet';
import ContactUsButton from './ContactUsButton';

const textDataUrl = page_json.page_jsons.Blogs;
const textDataResponse = await fetch(textDataUrl);
const textData = await textDataResponse.json();

function Blogs() {
	useEffect(() => {
		AOS.init(); // Initialize AOS
	}, []);

	return (
		<div data-aos="fade-up">
			<Helmet>
                <meta name="description" content="Engineersmind Blogs" />
                <title>Blogs | Resources | Engineersmind</title>
            </Helmet>
			<div className="container-fluid m-0 p-0 brd-crmb">
				<div className="container">
					<div className="row">
						<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
							<nav aria-label="breadcrumb">
								<ol className="breadcrumb">
									<li className="breadcrumb-item"><a href="/">Home </a></li>
									<li className="breadcrumb-item"><a href="/Resources">Resources </a></li>
									<li className="breadcrumb-item active" aria-current="page">Blogs</li>
								</ol>
							</nav>
						</div>
					</div>
				</div>
			</div>

			<div className="container-fluid thankyou">
				<div className="container">
					<h1>{textData.layer1.heading}</h1>
					<div className="row">
						<p>{textData.layer1.body.text1}</p>
						<p>{textData.layer1.body.text2}</p>
						{
							textData.layer1.editable_blogs.map((item, index) => {
								return (
									<div>
										<h3>{item.heading}</h3>
										<p>{item.body}</p>
										<br/>
									</div>
								)
							})
						}
						<a href="/">{textData.layer1.button.text}</a>
						&emsp;
                        <ContactUsButton linkOnly />
					</div>
				</div>
			</div>

			<div className="container-fluid trust">
				<div className="container trusted-img">
					<div className="row">
						<h1>{textData.layer2.heading}</h1>
						<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
							<img src={textData.layer2.img} alt="logos" className="img-fluid" />

						</div>
					</div>
				</div>
			</div>

			<div className="container-fluid alliance">
				<div className="container">
					<div className="row">
						<div className="col-lg-7 col-md-6 col-sm-12 col-xs-12">
							<div className="heading">
								<h1><span>{textData.layer3.heading.text1}</span>{textData.layer3.heading.text2}<span>{textData.layer3.heading.text3}</span></h1>
							</div>
							<div className="more-2">
								<a href={textData.layer3.button.url}>{textData.layer3.button.text}<i className="fa-solid fa-arrow-right"></i></a>
							</div>
						</div>
						<div className="col-lg-5 col-md-6 col-sm-12 col-xs-12">
							<div className="gif">
								<img src={textData.layer3.button.img} alt="gif" className="img-fluid" />
							</div>
						</div>
					</div>
				</div>
			</div>
            <div className="container-fluid ContactForm" id='ContactForm'>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <ContactForm />
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid tabg alliance">
				<div className="TA-bg">
					<div className="container">
						<div className="row">
							<h1>{textData.layer4.heading}</h1>
							<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
								<img src={textData.layer4.img} alt="logos" className="img-fluid" />

							</div>
						</div>
					</div>
				</div>
			</div>

		</div>
	)
}

export default Blogs;