import React, { useEffect, useState } from 'react';
import AOS from 'aos';
import page_json from './config/config.json';
//import Recaptcha from './ReCaptcha';
import ReCAPTCHA from "react-google-recaptcha";
import useLocation from "../utils/useLocation";

async function fetchData(url) {
  const response = await fetch(url);
  return response.json();
}

function ContactForm() {
  const [formData, setFormData] = useState({
    firstname: "",
    //lastname: '',
    email: "",
    phone: "",
    organisation: "",
    city: "",
    state: "",
    country: "",
    comment: "",
  });
  const [message, setMessage] = useState("");
  const [recaptchaToken, setRecaptchaToken] = useState(null);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [textData, setTextData] = useState({});
  const [countryList, setCountryList] = useState([]);
  const { location, error, loading } = useLocation();

  useEffect(() => {    
    if(location && !loading && !error){
      setFormData({
        ...formData,
        city: location.city || '',
        state: location.state || '',
        country: location.country || ''
      })
    }
  }, [location, error, loading])

  useEffect(() => {
    AOS.init();

    const loadData = async () => {
      const countries = await fetchData(page_json.backend_apis.countries);
      setCountryList(countries.data || []);

      const textDataResponse = await fetchData(page_json.page_jsons.Contact);
      setTextData(textDataResponse);
    };

    loadData();
  }, []);

  useEffect(() => {
    // Enable/disable the button based on form validation
    //alert("firstname-"+formData.firstname+"---lastname - "+formData.lastname+"---email - "+formData.email+"---phone - "+formData.phone+"---city - "+formData.city+"---country - "+formData.country+"---selectedOption - "+selectedOption+"---check1 - "+formData.check1+"---isCaptchaValid - "+isCaptchaValid);
    const isValid =
      formData.firstname && formData.email && recaptchaToken;

    setIsButtonDisabled(!isValid);
  }, [formData, recaptchaToken]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    //var valueFrm = formData;
        if (name === 'phone') {
      const regex = /^[0-9()+-,]*$/;
      if (value.length === 0 || regex.test(value)) {
        setMessage('');
      } else {
        setMessage('Please enter valid phone number.');
      }
    }

        setFormData(prev => ({
      ...prev,
            [name]: value
    }));
  };
  const handleRecaptchaChange = (token) => {
        setRecaptchaToken(token);  // Store the reCAPTCHA token
  };
  const btnclick = async (e) => {
    e.preventDefault();

    const requestData = {
      name: `${formData.firstname}`,
      email: formData.email,
      phone: formData.phone,
      organisation: formData.organisation,
      comment: formData.comment,
      recaptchaToken: recaptchaToken
    };

    let bodytext = `<div className="application-container"><h1></h1><div className="applicant-info"><p><strong>Name:</strong> ${requestData.name} </p><p><strong>Email:</strong> ${requestData.email} </p><p><strong>Phone:</strong> ${requestData.phone} </p><p><strong>Organization:</strong> ${requestData.organisation} </p></div><div className="comment"><h1>Comments</h1><p>${requestData.comment}</p></div></div>`;

    let emailData = {
      sender: page_json.contact_api.fromEmail,
      recipient: page_json.contact_api.toEmail,
      subject: `Enquiry for Contact Form Submission from page ${document.title}`,
      body_text: bodytext,
      body_html: `<html><body>${bodytext}</body></html>`,
    };

    const emailAPI = page_json.backend_apis.email_api;

    try {
      const response = await fetch(emailAPI, {
                method: 'POST',
        headers: {
                    'Content-Type': 'application/json',
        },
        body: JSON.stringify(emailData),
      });

      if (response.ok) {
        const responseData = await response.json();
                console.log('Response:', responseData);

                alert('Query sent successfully');
        window.location.reload();
        //setFormSubmitted(true);
      } else {
                console.error('Error:', response.statusText);
                alert('Query failed, try again!');
      }
    } catch (error) {
            console.error('Exception:', error);
            alert('An error occurred, please try again!');
    }
  };

  return (
    <div data-aos="fade-up">
      <div className="container-fluid contact-form">
        <div className="container">
          <h1>{textData?.layer4?.heading}</h1>
          <h4>{textData?.layer4?.body?.text1}</h4>
          <form onSubmit={btnclick} className="mt-3">
            <div className="row">
              <div className="col-lg-3 col-md-3 col-sm-3 col-xs-12">
                <div className="success1">
                  <input
                    type="text"
                    value={formData.firstname}
                    onChange={handleInputChange}
                    className="form-control"
                    name="firstname"
                    id="FirstName"
                    required
                    placeholder={textData?.layer4?.body?.text2}
                  />
                </div>
              </div>
              <div className="col-lg-3 col-md-3 col-sm-3 col-xs-12">
                <div className="success1">
                  <input
                    type="email"
                    value={formData.email}
                    onChange={handleInputChange}
                    className="form-control"
                    name="email"
                    id="Email"
                    required
                    placeholder={textData?.layer4?.body?.text4}
                  />
                </div>
              </div>
              <div className="col-lg-3 col-md-3 col-sm-3 col-xs-12">
                <div className="success1">
                  <input
                    type="number"
                    maxLength="10"
                    className="form-control"
                    value={formData.phone}
                    onChange={handleInputChange}
                    name="phone"
                    id="Phone"
                    title="Only numbers"
                    pattern="[0-9]{10}"
                    required
                    placeholder={textData?.layer4?.body?.text6}
                  />
                  {message && (
                    <p style={{ color: 'red' }}>{message}</p>
                  )}
                </div>
              </div>
              <div className="col-lg-3 col-md-3 col-sm-3 col-xs-12">
                <div className="success1">
                  <input
                    type="text"
                    value={formData.organisation}
                    onChange={handleInputChange}
                    className="form-control"
                    name="organisation"
                    id="Organisation"
                    placeholder={textData?.layer4?.body?.text5}
                  />
                </div>
              </div>

              <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12 mt-3">
                <div className="success1">
                  <input
                    type="text"
                    value={formData.city}
                    onChange={handleInputChange}
                    className="form-control"
                    name="city"
                    id="City"
                    required
                    placeholder={textData?.layer4?.body?.text11}
                  />
                </div>
              </div>
              <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12 mt-3">
                <div className="success1">
                  <input
                    type="email"
                    value={formData.state}
                    onChange={handleInputChange}
                    className="form-control"
                    name="state"
                    id="State"
                    required
                    placeholder={textData?.layer4?.body?.text12}
                  />
                </div>
              </div>
              <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12 mt-3">
                <div className="success1">
                  <select
                    id="Country"
                    name="country"
                    className="form-control"
                    onChange={handleInputChange}
                    required
                    value={formData.country}
                  >
                    <option value="">{textData?.layer4?.body?.text8}</option>
                    {countryList.map((item) => (
                      <option key={item.name} value={item.name}>
                        {item.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                <div className="success1">
                  <textarea
                    className="form-control"
                    value={formData.comment}
                    onChange={handleInputChange}
                    name="comment"
                    id="Comments"
                    rows="3"
                    placeholder={textData?.layer4?.body?.text9}
                  ></textarea>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6 recaptchadiv">
                <ReCAPTCHA
                                    sitekey={page_json.contact_api.recaptcha_site_key}  // Replace with your actual site key from Google reCAPTCHA
                  onChange={handleRecaptchaChange}
                />
              </div>
            </div>
            <button type="submit" id="clickButton" className="contact-submit-button" disabled={isButtonDisabled}>{textData?.layer4?.body?.text10}</button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default ContactForm;
