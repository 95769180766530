import React, { useEffect } from 'react';
import AOS from 'aos';
import page_json from './config/config.json';
import ContactForm from './ContactForm';
import { Helmet } from 'react-helmet';
import ContactUsButton from './ContactUsButton';

const textDataUrl = page_json.page_jsons.FinancialCs2;
const textDataResponse = await fetch(textDataUrl);
const textData = await textDataResponse.json();

function FinancialCs2() {
	useEffect(() => {
		AOS.init(); // Initialize AOS
	}, []);

	return (
		<div data-aos="fade-up">
			<Helmet>
                <meta name="description" content="Automating Trade Processing Systems with Integrated Solutions" />
                <title>Automating Trade Processing | Financial Services | Engineersmind</title>
            </Helmet>
			<div className="container-fluid m-0 p-0 brd-crmb">
				<div className="container">
					<div className="row">
						<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
							<nav aria-label="breadcrumb">
								<ol className="breadcrumb">
									<li className="breadcrumb-item"><a href="/">Home</a></li>
									<li className="breadcrumb-item"><a href="/Industries/Financial-Services">Financial Services</a></li>
									<li className="breadcrumb-item active" aria-current="page">Automating Trade Systems</li>
								</ol>
							</nav>
						</div>
					</div>
				</div>
			</div>

			<div className="container-fluid casestudy">
				<div className="container">
					<h1><span>{textData.layer1.heading.text1}</span>{textData.layer1.heading.text2}</h1>&nbsp;
					<div className="row">
						<div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
							<div className="casestudy1">
								<img src={textData.layer1.details.data1.img} alt="gif" className="img-fluid" />
								<h4>{textData.layer1.details.data1.heading} </h4>
								<p>{textData.layer1.details.data1.body} </p>
							</div>
						</div>
						<div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
							<div className="casestudy1">
								<img src={textData.layer1.details.data2.img} alt="gif" className="img-fluid" />
								<h4>{textData.layer1.details.data2.heading} </h4>
								<p>{textData.layer1.details.data2.body} </p>
							</div>
						</div>
						<div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
							<div className="casestudy1">
								<img src={textData.layer1.details.data3.img} alt="gif" className="img-fluid" />
								<h4>{textData.layer1.details.data3.heading} </h4>
								<p>{textData.layer1.details.data3.body} </p>
							</div>
						</div>
						<h5><span>{textData.layer2.heading}</span></h5>
						<div className="col-lg-3 col-md-3 col-sm-12 col-xs-12">
							<div className="casestudy1">
								<img src={textData.layer2.details.data1.img} alt="gif" className="img-fluid" />
								<p>{textData.layer2.details.data1.body}</p>
							</div>
						</div>
						<div className="col-lg-3 col-md-3 col-sm-12 col-xs-12">
							<div className="casestudy1">
								<img src={textData.layer2.details.data2.img} alt="gif" className="img-fluid" />
								<p>{textData.layer2.details.data2.body}</p>
							</div>
						</div>
						<div className="col-lg-3 col-md-3 col-sm-12 col-xs-12">
							<div className="casestudy1">
								<img src={textData.layer2.details.data3.img} alt="gif" className="img-fluid" />
								<p>{textData.layer2.details.data3.body}</p>
							</div>
						</div>
						<div className="col-lg-3 col-md-3 col-sm-12 col-xs-12">
							<div className="casestudy1">
								<img src={textData.layer2.details.data4.img} alt="gif" className="img-fluid" />
								<p>{textData.layer2.details.data4.body}</p>
							</div>
						</div>
					</div>
					<ContactUsButton className="light mt-2" />
				</div>
			</div>

			<div className="container-fluid Features">
				<div className="container">
					<h1>{textData.layer3.heading}</h1>&nbsp;
					<p>{textData.layer3.body}</p>&nbsp;
					<div className="row">
						<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
							<img src={textData.layer3.img} alt="gif" className="img-fluid" />
						</div>
					</div>
				</div>
			</div>

			<div className="container-fluid cs-solution-3">
				<div className="container">
					<h1><span>{textData.layer4.heading.text1}</span><br />{textData.layer4.heading.text2}</h1>
					<div className="row">
						<div className="custodian-border">
							<div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
								<div className="custodian">
									<p>{textData.layer4.modules.module1}</p>
								</div>
							</div>
							<div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
								<div className="custodian">
									<p>{textData.layer4.modules.module2}</p>
								</div>
							</div>
							<div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
								<div className="custodian">
									<p>{textData.layer4.modules.module3}</p>
								</div>
							</div>
						</div>
					</div>
					<div className="ul-list">
						<h1><span>{textData.layer5.heading.text1}</span><br />{textData.layer5.heading.text2}</h1>
						<div className="row">
							<div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
								<div className="record">
									<p><b>{textData.layer5.details.data1.heading}</b></p>
									<p>{textData.layer5.details.data1.text}<br />{textData.layer5.details.data1.text2}</p>
									<div className="row">
										<div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
											<div className="module-border-wrap">
												<div className="module">
													<p id="pdng">{textData.layer5.details.data1.modules.module1}</p>
												</div>
											</div>
										</div>
										<div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
											<div className="module-border-wrap">
												<div className="module">
													<p id="pdng">{textData.layer5.details.data1.modules.module2}</p>
												</div>
											</div>
										</div>
										<div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
											<div className="module-border-wrap">
												<div className="module">
													<p id="pdng">{textData.layer5.details.data1.modules.module3}</p>
												</div>
											</div>
										</div>
										<div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
											<div className="module-border-wrap">
												<div className="module">
													<p id="pdng">{textData.layer5.details.data1.modules.module4}</p>
												</div>
											</div>
										</div>
										<div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
											<div className="module-border-wrap">
												<div className="module">
													<p id="pdng">{textData.layer5.details.data1.modules.module5}</p>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
								<div className="record">
									<p><b>{textData.layer5.details.data2.heading}</b></p>
									<p>{textData.layer5.details.data2.text2}</p>
									<div className="row">
										<div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
											<div className="module-border-wrap">
												<div className="module">
													<p id="pdng">{textData.layer5.details.data2.modules.module1}</p>
												</div>
											</div>
										</div>
										<div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
											<div className="module-border-wrap">
												<div className="module">
													<p id="pdng">{textData.layer5.details.data2.modules.module2}</p>
												</div>
											</div>
										</div>
										<div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
											<div className="module-border-wrap">
												<div className="module">
													<p id="pdng">{textData.layer5.details.data2.modules.module3}</p>
												</div>
											</div>
										</div>
										<div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
											<div className="module-border-wrap">
												<div className="module">
													<p id="pdng">{textData.layer5.details.data2.modules.module4}</p>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
								<div className="record">
									<p><b>{textData.layer5.details.data3.heading}</b></p>
									<p>{textData.layer5.details.data3.text1}<br />{textData.layer5.details.data3.text2}</p>
									<div className="row">
										<div className="col-lg-10 col-md-10 col-sm-12 col-xs-12">
											<div className="module-border-wrap">
												<div className="module">
													<p id="pdng">{textData.layer5.details.data3.modules.module1}</p>
												</div>
											</div>
										</div>
										<div className="col-lg-2 col-md-2 col-sm-12 col-xs-12"></div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className="container-fluid Lifecycle">
				<div className="container">
					<h1>{textData.layer6.heading}</h1>&nbsp;
					<div className="row">
						<div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
							<div className="ul-list-1">
								<p>{textData.layer6.details.data1.serial_no}</p>
								<span>{textData.layer6.details.data1.text}</span>
							</div>
						</div>
						<div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
							<div className="ul-list-1">
								<p>{textData.layer6.details.data2.serial_no}</p>
								<span>{textData.layer6.details.data2.text}</span>
							</div>
						</div>
						<div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
							<div className="ul-list-1">
								<p>{textData.layer6.details.data3.serial_no}</p>
								<span>{textData.layer6.details.data3.text}</span>
							</div>
						</div>
						<div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
							<div className="ul-list-1">
								<p>{textData.layer6.details.data4.serial_no}</p>
								<span>{textData.layer6.details.data4.text}</span>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="container-fluid ContactForm" id='ContactForm'>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <ContactForm />
                        </div>
                    </div>
                </div>
            </div>
			<div className="TA-bg">
				<div className="container">
					<div className="row">
						<h1>{textData.layer7.heading}</h1>
						<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
							<img src={textData.layer7.img} alt="logos" className="img-fluid" />
						</div>
					</div>
				</div>
			</div>

		</div>
	)
}

export default FinancialCs2;