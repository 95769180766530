import React, { useEffect } from 'react';
import AOS from 'aos';
import page_json from './config/config.json';
import ContactForm from './ContactForm';

import { Helmet } from 'react-helmet';
import ContactUsButton from './ContactUsButton';

const textDataUrl = page_json.page_jsons.AboutUs;
const textDataResponse = await fetch(textDataUrl);
const textData = await textDataResponse.json();

function AboutUs() {
    useEffect(() => {
        AOS.init(); // Initialize AOS
    }, []);

    return (
        <div data-aos="fade-up">
            <Helmet>
                <meta name="description" content="Engineersmind is an American company specializiing in IT Solutions and Engineering services across industries. We customize solutions for businesses with digital engineering, automation, design and consulting, modernization and cloud transformation for organizations. Our solutions are core to your business, our integrated and tailored solutions advance your Enterprise to new heights. Our strategy and architecture fuels your competitive spirit and innovation. We forge robust partnership that will kindle a new future for you." />
                <title>About Us | Engineersmind</title>
            </Helmet>
            <div className="container-fluid m-0 p-0 brd-crmb">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><a href="/">Home </a></li>
                                    <li className="breadcrumb-item active" aria-current="page">About Us</li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container-fluid p-0">
                <div className="inner-banner">
                    <img src={textData.layer1.img} className="d-block w-100" alt="banner-img" />
                    <div className="inner-banner-text about-us">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-8 col-md-8 col-sm-8 col-xs-8">
                                    <p>{textData.layer1.body}</p>
                                    <h1 className="animate__animated animate__fadeInLeft">{textData.layer1.heading.text1}<br /> {textData.layer1.heading.text2} <span>{textData.layer1.heading.text3}<br /> {textData.layer1.heading.text4}</span> {textData.layer1.heading.text5}<br /> <span>{textData.layer1.heading.text6}</span></h1>
                                    <ContactUsButton />
                                </div>
                                <div className="col-lg-8 col-md-8 col-sm-8 col-xs-8"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container-fluid p-0">
                <img src={textData.layer2.img} className="d-block w-100" alt="banner-img" />
                <div className="container">
                    <div className="wwd">
                        <p>{textData.layer2.body}</p>
                        <h1><span>{textData.layer2.heading.text1}</span> {textData.layer2.heading.text2}</h1>
                    </div>
                </div>
            </div>

            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-4 col-md-12 col-sm-12 col-xs-12">
                        <div className="ai-text-4">
                            <p><b>{textData.layer3.body}</b></p>
                            <h1>{textData.layer3.heading}</h1>
                        </div>
                    </div>
                    <div className="col-lg-8 col-md-12 col-sm-12 col-xs-12">
                        <div className="ai-img">
                            <img src={textData.layer3.img} className="img-fluid" alt="industry" />
                        </div>
                    </div>
                </div>
            </div>

            <div className="container-fluid solution">
                <div className="container">
                    <div className="solutions">
                        <p>{textData.layer4.body1}</p>&nbsp;
                        <p>{textData.layer4.body2}</p>&nbsp;
                        <p>{textData.layer4.body3}</p>&nbsp;
                    </div>
                </div>
            </div>

            <div className="container-fluid leadership">
                <div className="container">
                    <div className="leadership">
                        <h1>{textData.layer5.heading.text1} <span>{textData.layer5.heading.text2}</span></h1>
                    </div>
                    <div className="row">
                        {
                            textData.layer5.editableList_leadership.map(function(item){
                                return(
                                    <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12 mb-3">
                                        <div className="leader-img">
                                            <img src={item.img} className="img-fluid" alt="industry" />
                                            <p className='text-white mt-2'>{item.name}</p>
                                            <p className='text-white'><i>{item.post}</i></p>
                                        </div>
                                    </div>)
                              })
                        }
                    </div>
                    <br/>
                    <a href={textData.layer5.clickbutton.url}>{textData.layer5.clickbutton.text} <i className="fa-solid fa-arrow-right"></i></a>
                    
                </div>
            </div>

            <div className="container-fluid trust">
                <div className="container trusted-img">
                    <div className="row">
                    <h1>{textData.layer6.heading}</h1>
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <img src={textData.layer6.img} alt="logos" className="img-fluid" />

                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid alliance">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-7 col-md-6 col-sm-12 col-xs-12">
                            <div className="heading">
                            <h1><span>{textData.layer7.heading.text1}</span> {textData.layer7.heading.text2} <span>{textData.layer7.heading.text3}</span></h1>
                            </div>
                            <div className="more-2">
                            <a href={textData.layer7.button.url}>{textData.layer7.button.text} <i className="fa-solid fa-arrow-right"></i></a>
                            </div>
                        </div>
                        <div className="col-lg-5 col-md-6 col-sm-12 col-xs-12">
                            <div className="gif">
                            <img src={textData.layer7.img} alt="gif" className="img-fluid" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid ContactForm" id="ContactForm">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <ContactForm />
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid tabg alliance">
                <div className="TA-bg">
                    <div className="container">
                        <div className="row">
                        <h1>{textData.layer8.heading}</h1>
                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <img src={textData.layer8.img} alt="logos" className="img-fluid" />

                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </div>
    )

}
export default AboutUs;