import { useState, useEffect } from "react";
import axios from "axios";
import page_json from "../components/config/config.json";

const useLocation = () => {
  const [location, setLocation] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchLocation = async () => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          async (position) => {
            const { latitude, longitude } = position.coords;

            try {
              const url = page_json.backend_apis.get_user_location_api;
              const response = await axios.get(url, {
                params: { latitude, longitude },
              });

              const data = response.data?.location;

              if (data && data.city && data.state && data.country) {
                setLocation({
                  city: data.city,
                  state: data.state,
                  country: data.country,
                });
              } else {
                setError("Unable to fetch location details.");
              }
            } catch (err) {
              setError(err.message);
            } finally {
              setLoading(false);
            }
          },
          (err) => {
            setError(err.message);
            setLoading(false);
          }
        );
      } else {
        setError("Geolocation is not supported by this browser.");
        setLoading(false);
      }
    };

    fetchLocation();
  }, []);

  return { location, error, loading };
};

export default useLocation;
