import React, { useEffect } from "react";
import AOS from "aos";
import page_json from "./config/config.json";
import ContactForm from "./ContactForm";
import { Helmet } from "react-helmet";
import ContactUsButton from "./ContactUsButton";

const textDataUrl = page_json.page_jsons.HealthcareCs1;
const textDataResponse = await fetch(textDataUrl);
const textData = await textDataResponse.json();

function HealthcareCs1() {
  useEffect(() => {
    AOS.init(); // Initialize AOS
  }, []);

  return (
    <div data-aos="fade-up" className="caseStudyPage">
      <Helmet>
        <meta
          name="description"
          content="Integrated Experiences for providers and patients"
        />
        <title>Integrated User Experiences | Healthcare | Engineersmind</title>
      </Helmet>
      <div className="container-fluid m-0 p-0 brd-crmb">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="/">Home</a>
                  </li>
                  <li className="breadcrumb-item">
                    <a href="/Industries/Healthcare">Healthcare</a>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Integrated User Experiences
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid p-0">
        <div className="inner-banner">
          <img
            src={textData.layer1.banner}
            className="d-block w-100"
            alt="banner-img"
          />
          <div className="inner-banner-text">
            <div className="container">
              <div className="row animate__animated animate__fadeInLeft">
                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                  <p>Case Study</p>
                  <h1>{textData.layer1.title}</h1>
				          <ContactUsButton />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid m-0 caseStudyContent">
        <div className="container">
          <div className="row">
            <div className="col-lg-2 col-md-12 col-sm-12 col-xs-12">
              <div className="d-flex flex-column leftSection w-max">
                <div className="logo-container">
                  <a href="/">
                    <img src={textData.leftSectionContent.logo} alt="EM_Logo" />
                  </a>
                </div>
                <p className="mt-1">
                  {textData.leftSectionContent.text1}{" "}
                  <strong>{textData.leftSectionContent.text2}</strong>
                </p>
                <hr />
                <p>{textData.leftSectionContent.text3}</p>
                <hr className="mb-5" />
              </div>
            </div>

            <div className="col-lg-10 col-md-12 col-sm-12 col-xs-12">
              <section>
                <h4>{textData.keyPointsSection.title}</h4>
                <p className="mb-3">{textData.keyPointsSection.subtitle}</p>
                <p className="mb-3">{textData.keyPointsSection.pointsTitle}</p>
                <ul>
                  {textData.keyPointsSection.points.map((point, index) => {
                    return (
                      <li key={index}>
                        <strong>{point.title}: </strong>
                        {point.description}
                      </li>
                    );
                  })}
                </ul>
              </section>

              <hr />

              <section className="overview">
                <h4 className="mb-3">{textData.overviewSection.title}</h4>
                <p
                  className="para"
                  dangerouslySetInnerHTML={{ __html: textData.overviewSection.description }}
                />
                <div className="d-flex justify-content-center my-5">
                  <img src={textData.overviewSection.img} alt="overview-img" />
                </div>
                {textData.overviewSection.points.map((point, index) => {
                  return <p className="mb-5">{point}</p>;
                })}

                <div className="helthcare-steps-container">
                  {textData.steps.map((step, index) => {
                    return (
                      <div
                        className={`d-flex ${
                          index % 2 === 0 ? "flex-row" : "flex-row-reverse"
                        } step`}
                        key={index}
                      >
                        <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                          <div className="image-wrapper">
                            <img src={step.img} alt="step-img" />
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6 text-content">
                          <p className="title">{step.title}</p>
                          <ul className="pl-1">
                            {step.points.map((point, index) => {
                              return (
                                <div
                                  className="d-flex w-100 list-item"
                                  key={index}
                                >
                                  <img
                                    src={textData.bulletPoints.img}
                                    alt="bullet-point"
                                  />
                                  <div>
                                    <p className="point">{point}</p>
                                  </div>
                                </div>
                              );
                            })}
                          </ul>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </section>

              <hr />

              <section>
                <h4 className="mb-3">{textData.outcome.title}</h4>
                <p className="mb-3">{textData.outcome.content}</p>
              </section>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid trust">
        <div className="container trusted-img">
          <div className="row">
            <h1>{textData.trustedBy.heading}</h1>
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <img
                src={textData.trustedBy.img}
                alt="logos"
                className="img-fluid"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid alliance">
        <div className="container">
          <div className="row">
            <div className="col-lg-7 col-md-6 col-sm-12 col-xs-12">
              <div className="heading">
                <h1>
                  <span>{textData.alliances.heading.text1}</span>
                  {textData.alliances.heading.text2}
                  <span>{textData.alliances.heading.text3}</span>
                </h1>
              </div>
              <div className="more-2">
                <a href={textData.alliances.button.url}>
                  {textData.alliances.button.text}
                  <i className="fa-solid fa-arrow-right"></i>
                </a>
              </div>
            </div>
            <div className="col-lg-5 col-md-6 col-sm-12 col-xs-12">
              <div className="gif">
                <img
                  src={textData.alliances.button.img}
                  alt="gif"
                  className="img-fluid"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <div className="container-fluid casestudy">
				<div className="container">
					<h1><span>{textData.layer1.heading1.text1}</span>{textData.layer1.heading1.text2}</h1>
					<div className="row">
						<div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
							<div className="casestudy1">
								<img src={textData.layer1.details.data1.img} alt="gif" className="img-fluid" />
								<h4>{textData.layer1.details.data1.heading}</h4>
								<p>{textData.layer1.details.data1.body}</p>
							</div>
						</div>
						<div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
							<div className="casestudy1">
								<img src={textData.layer1.details.data2.img} alt="gif" className="img-fluid" />
								<h4>{textData.layer1.details.data2.heading}</h4>
								<p>{textData.layer1.details.data2.body}</p>
							</div>

						</div>
						<div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
							<div className="casestudy1">
								<img src={textData.layer1.details.data3.img} alt="gif" className="img-fluid" />
								<h4>{textData.layer1.details.data3.heading}</h4>
								<p>{textData.layer1.details.data3.body}</p>
							</div>
						</div>
						<h5><span>{textData.layer1.heading2}</span></h5>
						<div className="col-lg-5 col-md-5 col-sm-12 col-xs-12">
							<div className="casestudy1">
								<p>{textData.layer1.body}</p>
							</div>
						</div>
					</div>
					<ContactUsButton className="light" />
				</div>
			</div> */}

      {/* <div className="container-fluid framework">
				<div className="container">
					<h1>{textData.layer2.heading}</h1>
					<div className="row">
						<div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
							<div className="frame-img">
								<img src={textData.layer2.details.data1.img} alt="gif" className="img-fluid" />
								<p>{textData.layer2.details.data1.title}</p>
							</div>
							<p>{textData.layer2.details.data1.body}</p>
						</div>
						<div className="col-lg-8 col-md-8 col-sm-12 col-xs-12">
							<div className="frame-text">
								<p><span>{textData.layer2.details.data1.points.point1.serial_no}</span> {textData.layer2.details.data1.points.point1.body}</p>
								<p><span>{textData.layer2.details.data1.points.point2.serial_no}</span> {textData.layer2.details.data1.points.point2.body}</p>
								<p><span>{textData.layer2.details.data1.points.point3.serial_no}</span> {textData.layer2.details.data1.points.point3.body}</p>
							</div>
						</div>
						&nbsp;
						<hr />
						<div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
							<div className="frame-img">
								<img src={textData.layer2.details.data2.img} alt="gif" className="img-fluid" />
								<p>{textData.layer2.details.data2.title}</p>
							</div>
							<p>{textData.layer2.details.data2.body}</p>
						</div>
						<div className="col-lg-8 col-md-8 col-sm-12 col-xs-12">
							<div className="frame-text">
								<p><span>{textData.layer2.details.data2.points.point1.serial_no}</span> {textData.layer2.details.data2.points.point1.body}</p>
								<p><span>{textData.layer2.details.data2.points.point2.serial_no}</span> {textData.layer2.details.data2.points.point2.body}</p>
							</div>
						</div>
						&nbsp;
						<hr />
						<div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
							<div className="frame-img">
								<img src={textData.layer2.details.data3.img} alt="gif" className="img-fluid" />
								<p>{textData.layer2.details.data3.title}</p>
							</div>
							<p>{textData.layer2.details.data3.body}</p>
						</div>
						<div className="col-lg-8 col-md-8 col-sm-12 col-xs-12">
							<div className="frame-text">
								<p><span>{textData.layer2.details.data3.points.point1.serial_no}</span> {textData.layer2.details.data3.points.point1.body}</p>
								<p><span>{textData.layer2.details.data3.points.point2.serial_no}</span> {textData.layer2.details.data3.points.point2.body}</p>
								<p><span>{textData.layer2.details.data3.points.point3.serial_no}</span> {textData.layer2.details.data3.points.point3.body}</p>
								<p><span>{textData.layer2.details.data3.points.point4.serial_no}</span> {textData.layer2.details.data3.points.point4.body}</p>
							</div>
						</div>
						&nbsp;
						<h1>{textData.layer3.heading}</h1>
						<p>{textData.layer3.body.text1}</p>&nbsp;
						<p>{textData.layer3.body.text2}</p>
						<div className="col-lg-2 col-md-2 col-sm-12 col-xs-12">
							<div className="module-border-wrap">
								<div className="module">
									<p id="pdng">{textData.layer3.modules.module1}</p>
								</div>
							</div>
						</div>
						<div className="col-lg-2 col-md-2 col-sm-12 col-xs-12">
							<div className="module-border-wrap">
								<div className="module">
									<p id="pdng">{textData.layer3.modules.module2}</p>
								</div>
							</div>
						</div>
						<div className="col-lg-2 col-md-2 col-sm-12 col-xs-12">
							<div className="module-border-wrap">
								<div className="module">
									<p id="pdng">{textData.layer3.modules.module3}</p>
								</div>
							</div>
						</div>
						<div className="col-lg-2 col-md-2 col-sm-12 col-xs-12">
							<div className="module-border-wrap">
								<div className="module">
									<p id="pdng">{textData.layer3.modules.module4}</p>
								</div>
							</div>
						</div>
						<div className="col-lg-3 col-md-3 col-sm-12 col-xs-12">
							<div className="module-border-wrap">
								<div className="module">
									<p id="pdng">{textData.layer3.modules.module5}</p>
								</div>
							</div>
						</div>
						<img src={textData.layer3.img} className="img-fluid" alt="aws" />
					</div>
				</div>
			</div> */}

      {/* <div className="container-fluid status">
				<div className="container">
					<h1><span>{textData.layer4.heading1.text1}</span>{textData.layer4.heading1.text2}<br />{textData.layer4.heading1.text3}</h1>
					<div className="row">
						<div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
							<div className="frame-img-1">
								<img src={textData.layer4.details.data1.img} alt="gif" className="img-fluid" />
								<p>{textData.layer4.details.data1.body}</p>
							</div>
							<div className="frame-img-1">
								<img src={textData.layer4.details.data2.img} alt="gif" className="img-fluid" />
								<p>{textData.layer4.details.data2.body}</p>
							</div>
							<div className="frame-img-1">
								<img src={textData.layer4.details.data3.img} alt="gif" className="img-fluid" />
								<p>{textData.layer4.details.data3.body}</p>
							</div>
						</div>
						<div className="col-lg-8 col-md-8 col-sm-12 col-xs-12">
							<ul className="numbers">
								<li><span>{textData.layer4.info.info1.serial_no}</span>{textData.layer4.info.info1.body}</li>
								<li><span>{textData.layer4.info.info2.serial_no}</span>{textData.layer4.info.info2.body}</li>
								<li><span>{textData.layer4.info.info3.serial_no}</span>{textData.layer4.info.info3.body}</li>
								<li><span>{textData.layer4.info.info4.serial_no}</span>{textData.layer4.info.info4.body}</li>
								<li><span>{textData.layer4.info.info5.serial_no}</span>{textData.layer4.info.info5.body}</li>
							</ul>
						</div>
					</div>&nbsp;
					<div className="status2">
						<h1><span>{textData.layer4.heading2}</span></h1>
						<p>{textData.layer4.body}</p>
					</div>

				</div>
			</div> */}

      <div className="container-fluid ContactForm" id="ContactForm">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <ContactForm />
            </div>
          </div>
        </div>
      </div>
      <div className="TA-bg">
        <div className="container">
          <div className="row">
            <h1>{textData.layer5.heading}</h1>
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <img
                src={textData.layer5.img}
                alt="logos"
                className="img-fluid"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HealthcareCs1;
